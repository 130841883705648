.menuBar {
  padding: 0 20px;
  border-bottom: solid 1px #e8e8e8;
 
  height: 80px;
}
.logo {
  width: 150px;
  float: left;
}
.logo a {
  display: inline-block;
  font-size: 20px;
  padding: 19px 20px;
  text-transform: capitalize;
}
.menuCon {
  width: calc(100% - 150px);
  float: right;
}
.menuCon .ant-menu-item {
  padding: 0px 5px;
}
.menuCon .ant-menu-submenu-title {
  padding: 10px 20px;
}
.menuCon .ant-menu-item a,
.menuCon .ant-menu-submenu-title a {
  padding: 10px 15px;
}
.menuCon .ant-menu-horizontal {
  border-bottom: none;
}


.barsMenu {
  float: right;
  height: 32px;
  padding: 6px;
  margin-top: 8px;
  display: none;
  background: none;
}
.barsBtn {
  display: block;
  width: 20px;
  height: 2px;
  background: #1890ff;
  position: relative;
}
.barsBtn:after,
.barsBtn:before {
  content: attr(x);
  width: 20px;
  position: absolute;
  top: -6px;
  left: 0;
  height: 2px;
  background: #1890ff;
}
.barsBtn:after {
  top: auto;
  bottom: -6px;
}
.ant-drawer-body {
  padding: 0;
}
.logoMobile{
  text-align: center;

}
.ant-drawer-body .ant-menu-horizontal > .ant-menu-item,
.ant-drawer-body .ant-menu-horizontal > .ant-menu-submenu {
  display: inline-block;
  width: 100%;
}
.ant-drawer-body .ant-menu-horizontal {
  border-bottom: none;
}
.ant-drawer-body .ant-menu-horizontal > .ant-menu-item:hover {
  border-bottom-color: transparent;
}
.rightMenu {
  float: right;    width: auto;margin-right: 10%;
}
.menuCon .ant-menu-item{
padding:5px 10px 0 0;
}
.menuCon .ant-menu-item{
margin:0 20px 0 0;
}
.menuCon .ant-menu-submenu-title{
padding-top: 5px;
}
.icon{
padding-left: 10px;
font-size: 22px;;
}
.ant-layout-header{
border-bottom:solid 1px #e8e8e8;
}
.LeftCarousel{
background-color: #C2BCBC;
}
.MyBtn{
background-color: #C2BCBC;
color:#66413B;
}


.MyBtn:hover{
color: #CC8989!important;
border-color: #CC8989!important;
}
.MyBtnFilled{
color: #C2BCBC;
background-color:#66413B;
font-size: 20px;
height: 100px;
}
.MyBtnFilled:hover{
color: #CC8989!important;
border-color: #CC8989!important;
}
.myBtn{
background-color: white;
margin-left: 10px;
border:1px solid grey;
width: 35px;
line-height: 30px;
}
.myBtn:hover{
color: #CC8989!important;
border-color: #CC8989!important;
} 
.MyBtnActive{
  color: #C2BCBC;
  background-color:#66413B;
  font-size: 20px;
height: 50px;
  }
  .MyBtnActive:hover{
  color: #CC8989!important;
  border-color: #CC8989!important;
  }
  .myPara{
    line-height: 10px;
  }
  .homeText{
    font-Family:'Brush Script';
    font-Size:40px;
    line-Height:50px;
    font-Style:italic;
    color:#C2BCBC;
    margin-Top:50px;
  }
  .homeText2{
    font-style: italic;
    font-family: auto;
    font-size: 23px;
    line-height: 63px;
    /* text-align: center; */
    color: #C2BCBC;
    margin: 0 105px 0 105px;
}
.EmbedFrame-footer{

  display: none;
}